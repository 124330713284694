'use client';

import { Spinner } from '~/app/_components/common';

const DashboardTeamPage = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center">
      <Spinner />
    </div>
  );
};

export default DashboardTeamPage;
